define(['jquery', 'selectize'],
	function ($, selectize) {
		selectize.define('scroll_parent', function (options) {
			var self = this;
			this.setup_original = this.setup;
			self.setup = (function () {
				var original = self.setup;
				return function () {
					original.apply(self, arguments);
					window.addEventListener('scroll', function (evt) {
						if (!$(evt.target).hasClass('selectize-dropdown-content')) {
							self.close();
						}
					}, true);
				};
			})();
		});
	});
define(['jquery', 'selectize'],
	function ($, selectize) {
		selectize.define('hidden_textfield', function (options) {
			var self = this;


			this.showInput = function () {
				this.$control.css({ cursor: 'pointer' });
				this.$control_input.css({ height: 'auto', opacity: 1, cursor: 'pointer', position: 'relative' });
				this.isInputHidden = false;
			};

			this.setup = (function () {
				var original = self.setup;
				return function () {
					original.apply(self, arguments);
					this.$control_input.prop('disabled', 'disabled');
				};
			})();
		});
	});